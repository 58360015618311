import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl'
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import greenback from '../images/home_background.jpg'
import fairchild from '../images/CFlogo.png'
import MothButtons from './MothButtons';
import thankYou from '../images/thank-you-tag.png'
import { Link } from 'gatsby'

const style = {
    margin: '0 auto',
    width: '850px',
    maxHeight: '95vh',
    overflow: 'auto',
    padding: '40px',
    color: "#F4B830 !important",
    //   border: '2px solid #000',
    //   boxShadow: 24,
    //   p: 4,
};

const inputStyles = theme => ({
    notchedOutline: {
      borderWidth: "2px !important",
      borderColor: "#F4B830 !important",
      marginBottom: '10px !important',
    },
    root: {
        color: "#F4B830 !important",
        fontFamily: '1906_french_newsnormal !important'
    }
  });

function LongImgModal({ modalState, handleClose, classes}) {
    useEffect(() => {
    }, []);

    return (
        <div>
        <Dialog
            open={modalState.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
        >
            <div style={style}>
                <img src={modalState.imgSrc} />
            </div>
        </Dialog>
        </div>
    );
}

export default withStyles(inputStyles)(LongImgModal)