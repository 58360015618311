import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  width: '540px',
  overflowY: 'scroll'
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
};

export default function PdfModal({ imgs, handleClose, modalState}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setNumPages(imgs.length);
  }, []);

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
      <Modal
        open={modalState.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img style={{margin:0}} src={imgs[pageNumber-1]} />
          {/* <Document
            file={modalState.pdfSrc}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document> */}
          <div className="pdf-nav-bar">
                <p className="pdf-page-nums">Page {pageNumber} of {numPages}</p>
                <div className="pdf-moths-wrapper">
                  <button className="moth-button pdf-moths" 
                  type
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  >
                      <div id="button-1" className="button-bg" />
                      <h2>Prev</h2>
                  </button>
                  <button className="moth-button pdf-moths" 
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  >
                      <div id="button-2" className="button-bg" />
                      <h2>Next</h2>
                  </button>
                </div>
              </div>  
        </Box>
      </Modal>
    </div>
  );
}
