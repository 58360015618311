import React, { useState } from 'react'
import GalleryBackground from '../../components/galleryBackground'
import articleRo from '../../images/rollovers/article-ro.png'
import brewery from '../../images/writing/articles/brewery.jpg'
import LongImgModal from '../../components/longImgModal'
import PdfModal from '../../components/pdfModal'
import hugh from '../../images/graphic-design/hughDorsey.pdf'
import hugh1 from '../../images/writing/hugh/hugh1.jpg'
import hugh2 from '../../images/writing/hugh/hugh2.jpg'
import hugh3 from '../../images/writing/hugh/hugh3.jpg'
import hugh4 from '../../images/writing/hugh/hugh4.jpg'
import hugh5 from '../../images/writing/hugh/hugh5.jpg'
import hugh6 from '../../images/writing/hugh/hugh6.jpg'

import "../layout.css"

export default function Articles() {
    const [modalState, setModalState] = useState({imgSrc:undefined, open:false})

    const handleClose = () => {
        setModalState(oldState => [{...oldState, open:false}])
    }

    const [pdfModalState, setPdfModalState] = useState({pdfSrc:undefined, open:false})

    const handlePdfClose = () => {
        setPdfModalState(oldState => [{...oldState, open:false}])
    }

    const imgs = [hugh1,hugh2,hugh3,hugh4,hugh5,hugh6]
    return (
        <GalleryBackground title="Articles" imgSrc={articleRo}>
            <div className="columns-container">
                <a href="/brewery.pdf">
                    <h2>line creek brewery</h2>
                </a>
                <h2 onClick={() => setPdfModalState({pdfSrc:hugh, open: true})}>a conscience divided against itself: hugh dorsey</h2>
            </div>
            <LongImgModal modalState={modalState} handleClose={handleClose} />
            <PdfModal imgs={imgs} modalState={pdfModalState} handleClose={handlePdfClose} />
        </GalleryBackground>
    )
}
